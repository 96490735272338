<template>
  <div v-lazy-background class="flex flex-col items-center w-full bg-[#F6F6F6] team-you-can-trust" data-bg="/team-you-can-trust-bg.webp">
    <!-- Heading -->
    <div class="text-center text-gray-900 font-medium font-montserrat lg:text-5xl text-4xl leading-tight mb-10 mt-12 p-5">
      Get the Job Done With a Team You Can Trust.
    </div>
    <ul
      role="list"
      class="grid grid-cols-1 lg:grid-cols-3 w-11/12 mx-auto"
    >
      <li v-for="(item, index) in items" :key="index">
        <ul role="list" class="flex items-center justify-center">
          <div class="m-3">
            <div
              class="group flex flex-col rounded-xl p-5 text-gray bg-white transition duration-300 ease-in-out hover:bg-[#6F8E91] hover:scale-105"
            >
              <div
                style="height: 520px;"
                class="group flex flex-col relative m-0 w-auto rounded-3xl ring-gray-900/5 sm:mx-auto sm:max-w-lg"
              >
                <div class="z-10 overflow-hidden rounded-xl">
                  <img v-lazy-load  :data-src="item.image" class="w-full block object-cover object-center" :alt="item.name">
                </div>
                <div class="w-full bottom-0 z-20 m-0 mt-2">
                  <div
                    class="text-neutral-900 text-3xl font-medium font-['Montserrat'] leading-[30px] mt-3 mb-3 group-hover:text-white"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="text-neutral-900 lg:text-xl text-lg font-medium font-['Montserrat'] leading-[30px] group-hover:text-white"
                  >
                    {{ item.role }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>
    </ul>
    <div class="cursor-pointer flex justify-center h-[60px] mt-10 mb-10">
      <CommonButtonsEstimate text="Meet Our Professionals" :icon="true" link="/our-team" />
    </div>
  </div>
</template>

<script setup lang="ts">
const items = [
  {
    image: '/our-team/dan-flatley.webp',
    name: 'Dan Flatley',
    role: 'Chief Executive Officer'
  },
  {
    image: '/our-team/chris-nasser.webp',
    name: 'Chris Nasser',
    role: 'General Manager'
  },
  {
    image: '/our-team/brad-sposito.webp',
    name: 'Brad Esposito',
    role: 'VP of Sales and Operations'
  }
]
</script>

<style scoped>
.team-you-can-trust {
  background-repeat: no-repeat;
  background-position: left top 44%;
}
</style>
